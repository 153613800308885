import React, { FC, useEffect, useState } from 'react';

import { Modal } from 'react-bootstrap';

import classes from './CustomModal.module.scss';
import Button from '../Button/Button';

interface CustomModalProps {
  shouldShow: boolean;
  click: () => void;
  bodyText: string;
  buttonText: string;
}

const CustomModal: FC<CustomModalProps> = ({
  shouldShow,
  click,
  bodyText,
  buttonText,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(shouldShow);
  }, [shouldShow]);

  return (
    <Modal centered show={show} onHide={click} dialogClassName={classes.Modal}>
      <Modal.Body className={classes.ModalBody}>{bodyText}</Modal.Body>
      <Modal.Footer className={classes.ModalFooter}>
        <Button type="button" onClick={click}>
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
